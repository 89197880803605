.header {
    background: black;
    padding-top: 10px;
    display: grid;
    grid-template-columns: 0 1fr auto 1fr 0;
    grid-template-rows: auto;
    grid-template-areas: "m_open logo menu back small_fb";
    text-align: center;
    justify-items: center;
    position: sticky;
    top: 0;
    z-index: 1;
}
.menu {
    grid-area: menu;
    padding: 0;
    z-index: 2;
}
.menu > div {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
}
.menu > div > a {
    padding: 0 15px;
    font-family: Averta CY;
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    color: #fffdf7;
}
.feedback {
    grid-area: back;
    color: white;
}
.logo {
    grid-area: logo;
}
.menu_btn {
    grid-area: m_open;
    align-self: center;
    display: none;
    overflow: hidden;
}
.small_fb {
    grid-area: small_fb;
    align-self: center;
    display: none;
    overflow: hidden;
}
@media screen and (max-width: 1000px) {
    .header {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: auto auto;
        grid-template-areas: "logo back" "menu menu";
    }
}
@media screen and (max-width: 600px) {
    .header {
        grid-template-columns: 1fr auto 1fr;
        grid-template-rows: auto 0;
        grid-template-areas: "m_open logo small_fb" "menu menu menu";
    }
    .feedback {
        display: none;
    }
    .small_fb {
        display: initial;
    }
    .menu_btn {
        display: initial;
    }
    .menu {
        width: 100%;
        padding: 0;
        margin: 0;
    }
    .menu > div {
        flex-direction: column;
        background: black;
        opacity: 0.8;
        align-items: stretch;
        transform: translateX(-100%);
        transition-duration: 0.5s;
        width: 100%;
    }
    .menu > div > a {
        padding: 15px;
        border-top: 1px solid white;
        border-bottom: 1px solid white;
    }
    .menu.open > div {
        transform: none;
    }
}
