html,
body {
    margin: 0;
    padding: 0;
}
a {
    text-decoration: none;
    color: inherit;
}
ul {
    list-style-type: none;
}
img {
    width: 100%;
    object-fit: cover;
}
