.circular {
    height: 0;
    padding-top: calc(5 / 4 * 100%);
    position: relative;
    min-width: 100px;
}
.circular > .cir {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-template-areas:
        "_3 _3 _3 _3"
        "_3 _3 _3 _3"
        "_3 _3 _3 _3"
        "_2 _c _c _1"
        "_l _c _c _r";
}
.circular > .cir > .image {
    grid-area: 1 / 1 / 5 / 5;
    border-radius: 50%;
    box-shadow: 0px 15px 30px rgba(34, 28, 14, 0.4);
    overflow: hidden;
    pointer-events: none;
}
.circular > .cir > .image > * {
    height: 100%;
    width: 100%;
}
.circular > .cir > .circ {
    grid-area: _c;
    border: 1px solid #ad922f;
    border-radius: 50%;
    overflow: hidden;
    padding-top: 75%;
    text-align: center;
    z-index: -1;
}
.circular > .cir > .circ > * {
    transform: translateY(-50%);
}
.circular > .cir > .left {
    grid-area: _l;
    pointer-events: all;
    justify-self: center;
    align-self: center;
}
.circular > .cir > .rigth {
    grid-area: _r;
    justify-self: center;
    pointer-events: all;
    align-self: center;
}
