.outter {
    overflow: hidden;
    height: 100%;
}
.container {
    display: flex;
    height: 100%;
    align-items: stretch;
    overflow: hidden;
    width: calc(var(--n) * 100%);
    transform: translate(calc(var(--tx, 0px) + 2 / var(--n) * -100%));
}
@keyframes move {
    from {
        transform: translate(
            calc(var(--otx, 0px) + var(--offset) / var(--n) * -100%)
        );
    }
    to {
        transform: translate(calc(var(--tx, 0px) + 2 / var(--n) * -100%));
    }
}
.element {
    width: 100%;
    height: 100%;
    width: calc(100% / var(--n));
    user-select: none;
    pointer-events: none;
}
.smooth .container {
    animation: move calc(var(--f, 1) * 0.5s) linear 0s 1 normal forwards;
}
