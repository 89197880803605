footer {
    padding: 20px;
    background: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
.media {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.media > * {
    padding: 5px;
}
.svg {
    width: initial;
}
