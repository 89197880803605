.button {
    background: #ad922f;
    box-shadow: 0px 0px 15px #ad922f;
    border-radius: 30px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 30px;
    color: white;
    text-decoration: none;
    display: inline-block;
    width: max-content;
    cursor: pointer;
    pointer-events: all;
}
.button::after {
    content: url(./image.svg);
    padding-left: 5px;
}
