.price {
    padding: 0 15px;
}
.num {
    font-size: 30px;
    line-height: 37px;
    white-space: nowrap;
    font-weight: bold;
}
.add {
    font-size: 10px;
}
@media screen and (max-width: 400px) {
    .num {
        font-size: 24px;
        line-height: 30px;
    }
}