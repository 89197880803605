.raito_box_out {
    height: 0;
    overflow: hidden;
    padding-top: calc(100% * var(--raito, 1));
    background: white;
    position: relative;
}
.raito_box_in {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
