.h1 {
    font-size: 48px;
    line-height: 59px;
    font-weight: bold;
}
.h2 {
    font-size: 36px;
    line-height: 44px;
}
.h3 {
    font-size: 24px;
    line-height: 30px;
}
.h4 {
    font-size: 20px;
    line-height: 25px;
}
.h5 {
    font-size: 16px;
    line-height: 20px;
}
.h6 {
    font-size: 12px;
    line-height: 15px;
}
@media screen and (max-width: 1400px) {
    .h1 {
        font-size: 24px;
        line-height: 30px;
    }
    .h2 {
        font-size: 20px;
        line-height: 100%;
    }
    .h3 {
        font-size: 16px;
        line-height: 20px;
    }
    .h4 {
        font-size: 12px;
        line-height: 15px;
    }
    .h5 {
        font-size: 12px;
        line-height: 15px;
    }
    .h6 {
        font-size: 10px;
        line-height: 12px;
    }
}
.bold {
    font-weight: bold;
}
